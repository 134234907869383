import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
})

export default {
  getUsers (perPage, page, token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/users/?limit=${perPage}&page=${page}`)
  },
  searchUsers (perPage, page, token, email) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/users/?limit=${perPage}&page=${page}&email=${email}`)
  },
  getUserById (id, token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/users/${id}`)
  },
  updateUserById (id, token, user) {
      apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
      return apiClient.put(`/users/${id}`, { user })
    },
  updateUserRole (id, token, user) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/users/${id}/role`, { user })
  },
}
