import TicketService from '@/services/TicketService'

export const namespaced = true

export const state = {
  ticketInfo: [],
  ticketInfoObject: null,
  totalRevenue: 0,
  error: {},
  isLoading: false,
}
export const mutations = {
  SET_TICKET_INFO (state, ticketInfo) {
    state.ticketInfo = ticketInfo
  },
  RESET_TICKET_INFO (state) {
    state.ticketInfo = []
  },
  SET_TICKET_INFO_OBJECT (state, ticketInfoObject) {
    state.ticketInfoObject = ticketInfoObject
  },
  RESET_TICKET_INFO_OBJECT (state) {
    state.ticketInfoObject = null
  },
  SET_TICKET_TOTAL_REVENUE (state, totalRevenue) {
    state.totalRevenue = totalRevenue
  },
  RESET_TICKET_TOTAL_REVENUE (state) {
    state.totalRevenue = 0
  },
  SET_LOADER (state, status) {
    state.isLoading = status
  },
  SET_ERROR (state, error) {
    state.error = error
  },
  RESET_ERROR (state) {
    state.error = {}
  },
}
export const actions = {
  getRemainingTickets ({ commit }, { slug }) {
    commit('SET_LOADER', true)
    commit('RESET_TICKET_INFO', true)
    commit('RESET_TICKET_INFO_OBJECT', true)
    commit('RESET_ERROR')
    return TicketService.getRemainingTickets(slug)
      .then((response) => {
        if (response.data.success) {
          commit('SET_TICKET_INFO', response.data.ticketsArray)
          commit('SET_TICKET_INFO_OBJECT', response.data.tickets)
          commit('SET_TICKET_TOTAL_REVENUE', response.data.totalRevenue)
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_ERROR', error.response.data)
        } else {
          commit('SET_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
}

export const getters = {

}
