import UserService from '@/services/UserService.js'

export const namespaced = true

export const state = {
  user: {},
  users: [],
  currentUser: {},
  country: null,
  isLoading: false,
  isLoggedIn: false,
  authError: {},
  successMessage: {},
}

export const mutations = {
  SET_USER (state, user) {
    state.user = user
  },
  SET_CURRENT_USER (state, currentUser) {
    state.currentUser = currentUser
  },
  SET_COUNTRY (state, country) {
    state.country = country
  },
  SET_LOADER (state, status) {
    state.isLoading = status
  },
  SET_LOGGED_IN_STATUS (state, status) {
    state.isLoggedIn = status
  },
  SET_AUTH_ERROR (state, error) {
    state.authError = error
  },
  RESET_AUTH_ERROR (state) {
    state.authError = {}
  },
  LOGOUT_CURRENT_USER (state) {
    state.currentUser = {}
    state.isLoggedIn = false
  },
  SET_MESSAGE (state, successMessage) {
    state.successMessage = successMessage
  },
  RESET_MESSAGE (state) {
    state.successMessage = {}
  },
}

export const actions = {
  loginUser ({ commit }, { user }) {
    commit('SET_LOADER', true)
    return UserService.loginUser(user)
      .then(response => {
        if (response.data.success) {
          const token = response.data.token
          const currentUser = response.data.user
          localStorage.setItem('token', token)
          localStorage.setItem('currentUser', JSON.stringify(currentUser))
          commit('SET_CURRENT_USER', { ...currentUser, token })
          commit('SET_LOADER', false)
          commit('SET_LOGGED_IN_STATUS', true)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_AUTH_ERROR', error.response.data.errors)
        } else {
          commit('SET_AUTH_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  getCurrentUser ({ commit }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
    const token = localStorage.getItem('token')

    if (currentUser && token) {
      commit('SET_CURRENT_USER', { ...currentUser, token })
      commit('SET_LOGGED_IN_STATUS', true)
    } else {
      commit('SET_CURRENT_USER', currentUser)
      commit('SET_LOGGED_IN_STATUS', false)
    }
    commit('SET_COUNTRY', localStorage.getItem('country'))
  },
  resetAuthError ({ commit }) {
    commit('RESET_AUTH_ERROR')
  },
  logoutCurrentUser ({ commit }, { token }) {
    commit('SET_LOADER', true)
    return UserService.logoutUser(token)
      .then(response => {
        if (response.data.success) {
          commit('LOGOUT_CURRENT_USER')
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_AUTH_ERROR', error.response.data.errors)
        } else {
          commit('SET_AUTH_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  signUpUser ({ commit }, { user }) {
    commit('SET_LOADER', true)
    return UserService.signUpUser(user)
      .then(response => {
        if (response.data.success) {
          commit('SET_MESSAGE', 'Account created')
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response && error.response.data.statusCode === 400) {
          if (error.response.data.errors.message === 'email must be unique') {
            commit('SET_AUTH_ERROR', { email: error.response.data.errors.message })
          }
        }
        if (error.response && error.response.data.statusCode === 422) {
          commit('SET_AUTH_ERROR', error.response.data)
        }
        commit('SET_LOADER', false)
      })
  },
  requestReset ({ commit }, { user }) {
    commit('SET_LOADER', true)
    return UserService.requestReset(user)
      .then(response => {
        if (response.data.success) {
          commit('SET_MESSAGE', response.data)
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_AUTH_ERROR', error.response.data)
        } else {
          commit('SET_AUTH_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  resetSuccessMessage ({ commit }) {
    commit('RESET_MESSAGE')
  },
  resetPassword ({ commit }, { user, token }) {
    commit('SET_LOADER', true)
    return UserService.resetPassword(user, token)
      .then(response => {
        if (response.data.success) {
          commit('SET_MESSAGE', response.data)
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_AUTH_ERROR', error.response.data)
        } else {
          commit('SET_AUTH_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },

  verifyEmail ({ commit }, { token }) {
    commit('SET_LOADER', true)
    return UserService.verifyEmail(token)
      .then(response => {
        if (response.data.success) {
          commit('SET_USER', response.data.user)
          commit('SET_MESSAGE', 'Account verified')
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_AUTH_ERROR', error.response.data)
        } else {
          commit('SET_AUTH_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
}

export const getters = {
  isLoggedIn: state => state.isLoggedIn,
}
