import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
})

export default {
  getEvents (perPage, page, country, token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/events/adminfeed?limit=${perPage}&page=${page}&country=${country}`)
  },
  searchEvents (perPage, page, country, token, city, name) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/events/adminfeed?limit=${perPage}&page=${page}&country=${country}&name=${name}&city=${city}`)
  },
  getMyEvents (perPage, page, token, country) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/events/feed?limit=${perPage}&page=${page}&country=${country}`)
  },
  getEvent (slug, token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/events/${slug}`)
  },
  createEvent (event, token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/events/', { event })
  },
  updateEvent (event, slug, token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/events/${slug}`, { event })
  },
  reviewVerifyBlockEvent (event, slug, token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/events/${slug}`, { event })
  },
  deleteEvent (slug, token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/events/${slug}`)
  },
  getEventWithInvites (slug, token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/events/${slug}/invites`)
  },
  inviteGuest (user, slug, token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/events/${slug}/invites`, { user })
  },
}
