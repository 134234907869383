import moment from 'moment'

const splitDateTime = (datetime, enddatetime) => {
  return {
    date: moment(datetime).format('YYYY-MM-DD'),
    time: moment(datetime).format('HH:mm'),
    enddate: moment(enddatetime).format('YYYY-MM-DD'),
    endtime: moment(enddatetime).format('HH:mm'),
  }
}

export default splitDateTime
