import ProfileService from '@/services/ProfileService.js'

export const namespaced = true

export const state = {
  perPage: 30,
  profile: {
    firstName: '---',
    lastName: '---',
    email: '--@--.--',
    username: '---',
    phone: '---',
  },
  updatedProfile: null,
  profiles: [],
  metadata: {},
  verificationMessage: {},
  isLoading: false,
  profileError: {},
  profilesError: {},
  updatedProfileError: {},
}

export const mutations = {
  SET_PROFILES (state, profiles) {
    state.profiles = profiles
  },
  SET_PROFILES_METADATA (state, metadata) {
    state.metadata = metadata
  },
  SET_MYPROFILE (state, profile) {
    state.profile = profile
  },
  SET_UPDATED_PROFILE (state, updatedProfile) {
    state.updatedProfile = updatedProfile
  },
  SET_MESSAGE (state, verificationMessage) {
    state.verificationMessage = verificationMessage
  },
  RESET_MESSAGE (state) {
    state.verificationMessage = {}
  },
  SET_LOADER (state, status) {
    state.isLoading = status
  },
  SET_MYPROFILE_ERROR (state, error) {
    state.profileError = error
  },
  RESET_MYPROFILE_ERROR (state) {
    state.profileError = {}
  },
  SET_PROFILES_ERROR (state, error) {
    state.profilesError = error
  },
  RESET_PROFILES_ERROR (state) {
    state.profilesError = {}
  },
  SET_UPDATED_PROFILE_ERROR (state, error) {
    state.updatedProfileError = error
  },
  RESET_UPDATED_PROFILE_ERROR (state) {
    state.updatedProfileError = {}
  },
}

export const actions = {
  getProfiles ({ commit }, { perPage = state.perPage, page, token }) {
    commit('SET_LOADER', true)
    return ProfileService.getUsers(perPage, page, token)
      .then(response => {
        if (response.data.success) {
          commit('SET_PROFILES', response.data.users)
          commit('SET_PROFILES_METADATA', response.data.meta)
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_PROFILES_ERROR', error.response.data)
        } else {
          commit('SET_PROFILES_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  searchProfiles ({ commit }, { perPage = state.perPage, page, token, email }) {
    commit('SET_LOADER', true)
    return ProfileService.searchUsers(perPage, page, token, email)
      .then(response => {
        if (response.data.success) {
          commit('SET_PROFILES', response.data.users)
          commit('SET_PROFILES_METADATA', response.data.meta)
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_PROFILES_ERROR', error.response.data)
        } else {
          commit('SET_PROFILES_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  getMyProfile ({ commit }, { id, token }) {
    commit('SET_LOADER', true)
    return ProfileService.getUserById(id, token)
      .then(response => {
        if (response.data.success) {
          commit('SET_MYPROFILE', response.data.user)
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_MYPROFILE_ERROR', error.response.data)
        } else {
          commit('SET_MYPROFILE_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  resetProfileError ({ commit }) {
    commit('RESET_MYPROFILE_ERROR')
  },
  updateMyProfile ({ commit }, { id, token, user }) {
    commit('SET_LOADER', true)
    return ProfileService.updateUserById(id, token, user)
      .then(response => {
        const currentUser = response.data.user
        localStorage.setItem('currentUser', JSON.stringify(currentUser))
        commit('SET_MYPROFILE', { ...currentUser, message: 'Profile updated' })
        commit('SET_LOADER', false)
      })
      .catch(error => {
        if (error.response) {
          commit('SET_MYPROFILE_ERROR', error.response.data)
        } else {
          commit('SET_MYPROFILE_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  updateUserRole ({ commit }, { id, token, user }) {
    commit('SET_LOADER', true)
    commit('RESET_UPDATED_PROFILE_ERROR')
    return ProfileService.updateUserRole(id, token, user)
      .then(response => {
        commit('SET_UPDATED_PROFILE', response.data.user)
        commit('SET_LOADER', false)
      })
      .catch(error => {
        if (error.response) {
          commit('SET_UPDATED_PROFILE_ERROR', error.response.data)
        } else {
          commit('SET_UPDATED_PROFILE_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
}

export const getters = {

}
