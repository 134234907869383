import Vue from 'vue'
import Vuex from 'vuex'
import * as eventsData from '@/store/modules/events.js'
import * as userData from '@/store/modules/users.js'
import * as profileData from '@/store/modules/profile.js'
import * as webhooksData from '@/store/modules/webhooks.js'
import * as notificationsData from '@/store/modules/notifications.js'
import * as ticketsData from '@/store/modules/tickets.js'
import * as countryList from '@/utils/countries'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    categories: [
      'entertainment',
      'fashion',
      'technology',
      'sports',
      'travel',
      'political',
      'academic',
      'corporate',
      'religious',
    ],
    countries: countryList.countries,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
  },
  actions: {
  },
  modules: {
    eventsData,
    userData,
    profileData,
    webhooksData,
    notificationsData,
    ticketsData,
  },
})
