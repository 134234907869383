import NotificationService from '@/services/NotificationService.js'

export const namespaced = true

export const state = {
  message: null,
  isLoading: false,
  error: null,
}

export const mutations = {
  SET_MESSAGE (state, message) {
    state.message = message
  },
  RESET_MESSAGE (state) {
    state.message = null
  },
  SET_LOADER (state, status) {
    state.isLoading = status
  },
  SET_NOTIFICATIONS_ERROR (state, error) {
    state.error = error
  },
  RESET_NOTIFICATIONS_ERROR (state) {
    state.error = null
  },
}

export const actions = {
  sendPushNotification ({ commit }, { token, notification }) {
    commit('SET_LOADER', true)
    commit('RESET_MESSAGE')
    commit('RESET_NOTIFICATIONS_ERROR')
    return NotificationService.sendPushNotification(token, notification)
      .then(response => {
        if (response.data.success) {
          commit('SET_MESSAGE', response.data.notifications.message)
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          const message = error.response.data.statusCode === 401 ? error.response.data : 'Push notification not sent'
          commit('SET_NOTIFICATIONS_ERROR', message)
        } else {
          commit('SET_NOTIFICATIONS_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  resetNotificationsError ({ commit }) {
    commit('RESET_NOTIFICATIONS_ERROR')
  },
}

export const getters = {

}
