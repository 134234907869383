<template>
  <base-material-card
    class="v-card--material-chart"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:heading>
      <div class="post-thumb d-block">
        <img
          v-lazy="{ src:poster.url }"
          alt="event poster"
          class="img-poster"
        >
      </div>
    </template>

    <slot
      slot="reveal-actions"
      name="reveal-actions"
    />

    <slot />

    <slot
      slot="actions"
      name="actions"
    />
  </base-material-card>
</template>

<script>
  export default {
    name: 'MaterialChartCard',

    inheritAttrs: false,

    props: {
      poster: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>

<style lang="sass">
  .v-card--material-chart
    p
      color: #999

    .v-card--material__heading

      .ct-label
        color: inherit
        opacity: .7
        font-size: 0.975rem
        font-weight: 100

      .ct-grid
        stroke: rgba(255, 255, 255, 0.2)

      .ct-series-a .ct-point,
      .ct-series-a .ct-line,
      .ct-series-a .ct-bar,
      .ct-series-a .ct-slice-donut
          stroke: rgba(255,255,255,.8)

      .ct-series-a .ct-slice-pie,
      .ct-series-a .ct-area
          fill: rgba(255,255,255,.4)
</style>
<style scoped>
/*-- Thumbnail Poster --*/
.post-thumb
{
  border-radius: .25em;
  display: block;
  width: 100%;
  position: relative;
  height: 0;
  padding: 50% 0 0 0;
  overflow: hidden;
}
img.img-poster
{
  position: absolute;
  display: block;
  max-width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
img[lazy=loading] {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #efc509;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
img[lazy=error] {
  width: 100%;
  height: 100%;
  /* background: url("../../assets/images/og-image.png") no-repeat center center; */
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
