import EventService from '@/services/EventService.js'
import splitDateTime from '@/utils/splitDateTime'

export const namespaced = true

export const state = {
  events: [],
  event: {},
  invites: [],
  invitesEvent: null,
  invite: {},
  metadata: {},
  perPage: 12,
  isLoading: false,
  eventError: {},
  eventMessage: {},
  isOwner: false,
  countryPickerModalOpen: false,
}
export const mutations = {
  SET_EVENTS (state, events) {
    state.events = events
  },
  RESET_EVENTS (state) {
    state.events = []
  },
  SET_INVITES (state, invites) {
    state.invites = invites
  },
  SET_INVITES_EVENT (state, invitesEvent) {
    state.invitesEvent = invitesEvent
  },
  RESET_INVITES (state) {
    state.invites = []
  },
  SET_INVITE (state, invite) {
    state.invite = invite
  },
  RESET_INVITE (state) {
    state.invite = {}
  },
  SET_EVENTS_METADATA (state, metadata) {
    state.metadata = metadata
  },
  SET_EVENT (state, event) {
    state.event = event
  },
  SET_EVENT_MESSAGE (state, eventMessage) {
    state.eventMessage = eventMessage
  },
  SET_IS_OWNER (state, status) {
    state.isOwner = status
  },
  RESET_EVENT (state) {
    state.event = {
      category: '........',
      name: '........',
      author: {
        firstName: '....',
        lastName: '....',
      },
    }
  },
  SET_LOADER (state, status) {
    state.isLoading = status
  },
  SET_EVENT_ERROR (state, error) {
    state.eventError = error
  },
  RESET_EVENT_ERROR (state) {
    state.eventError = {}
  },
  TOGGLE_COUNTRY_PICKER (state, status) {
    state.countryPickerModalOpen = status
  },
}
export const actions = {
  fetchEvents ({ commit, state }, { perPage = state.perPage, page }) {
    const country = localStorage.getItem('country') || 'Kenya'
    const token = localStorage.getItem('token')

    if (!country) {
      commit('TOGGLE_COUNTRY_PICKER', true)
      return
    }

    commit('SET_LOADER', true)
    commit('RESET_EVENT_ERROR')
    return EventService.getEvents(perPage, page, country, token)
      .then(response => {
        commit('SET_EVENTS_METADATA', response.data.meta)
        commit('SET_EVENTS', response.data.events)

        localStorage.setItem('adminEvents', JSON.stringify(response.data.events))
        commit('SET_LOADER', false)
      })
      .catch(error => {
        if (error.response) {
          commit('SET_EVENT_ERROR', error.response.data)
        } else {
          const offlineEvents = JSON.parse(localStorage.getItem('adminEvents')) || []
          commit('SET_EVENTS', offlineEvents)
        }
        commit('SET_LOADER', false)
      })
  },
  searchEvents ({ commit, state }, { perPage = state.perPage, page, city, name }) {
    const country = localStorage.getItem('country') || 'Kenya'
    const token = localStorage.getItem('token')

    if (!country) {
      commit('TOGGLE_COUNTRY_PICKER', true)
      return
    }

    commit('SET_LOADER', true)
    commit('RESET_EVENT_ERROR')
    return EventService.searchEvents(perPage, page, country, token, city, name)
      .then(response => {
        commit('SET_EVENTS_METADATA', response.data.meta)
        commit('SET_EVENTS', response.data.events)

        commit('SET_LOADER', false)
      })
      .catch(error => {
        if (error.response) {
          commit('SET_EVENT_ERROR', error.response.data)
        } else {
          commit('SET_EVENT_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  fetchMyEvents ({ commit, state }, { perPage = state.perPage, page }) {
    commit('RESET_EVENTS')
    commit('RESET_EVENT_ERROR')
    const token = localStorage.getItem('token')
    const country = localStorage.getItem('country')
    commit('SET_LOADER', true)
    return EventService.getMyEvents(perPage, page, token, country)
      .then(response => {
        commit('SET_EVENTS_METADATA', response.data.meta)
        commit('SET_EVENTS', response.data.events)
        commit('SET_LOADER', false)
      })
      .catch(error => {
        if (error.response) {
          commit('SET_EVENT_ERROR', error.response.data)
        } else {
          commit('SET_EVENT_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  setEvent ({ commit }, { event }) {
    const date = splitDateTime(event.date, event.enddate)
    commit('SET_EVENT', {
      ...event,
      date: date.date,
      time: date.time,
      enddate: date.enddate,
      endtime: date.endtime,
      datetime: event.date,
      enddatetime: event.enddate,
})
  },
  fetchEvent ({ commit, dispatch, getters }, { slug, token }) {
    commit('SET_LOADER', true)
    commit('RESET_EVENT_ERROR')
    const event = getters.getEventBySlug(slug)
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))

    if (event) {
      dispatch('setEvent', { event })
      dispatch('setIsOwner', { currentUser, event })
      commit('SET_LOADER', false)
    } else {
      return EventService.getEvent(slug, token)
        .then(response => {
          const { event } = response.data
          dispatch('setEvent', { event })
          dispatch('setIsOwner', { currentUser, event })
          commit('SET_LOADER', false)
        })
        .catch(error => {
          if (error.response) {
            commit('SET_EVENT_ERROR', error.response.data)
          } else {
            commit('SET_EVENT_ERROR', error.message)
          }
          commit('SET_LOADER', false)
        })
    }
  },
  resetEvents ({ commit }) {
    commit('RESET_EVENTS')
  },
  setIsOwner ({ commit }, { currentUser, event }) {
    if (currentUser && event.author.id === currentUser.id) {
      commit('SET_IS_OWNER', true)
    } else {
      commit('SET_IS_OWNER', false)
    }
  },
  resetEvent ({ commit }) {
    commit('RESET_EVENT')
  },
  createEvent ({ commit }, { event, token }) {
    commit('SET_LOADER', true)
    commit('RESET_EVENT_ERROR')
    return EventService.createEvent(event, token)
      .then((response) => {
        if (response.data.success) {
          commit('SET_EVENT', response.data.event)
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_EVENT_ERROR', error.response.data)
        } else {
          commit('SET_EVENT_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  resetEventError ({ commit }) {
    commit('RESET_EVENT_ERROR')
  },
  deleteEvent ({ commit, dispatch }, { slug, token }) {
    commit('SET_LOADER', true)
    commit('RESET_EVENT_ERROR')
    return EventService.deleteEvent(slug, token)
      .then((response) => {
        dispatch('resetEvent')
        commit('SET_EVENT_MESSAGE', response)
        commit('SET_LOADER', false)
      })
      .catch((error) => {
        if (error.response) {
          commit('SET_EVENT_ERROR', error.response.data)
        } else {
          commit('SET_EVENT_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  reviewVerifyBlockEvent ({ commit, dispatch }, { event, slug, token }) {
    commit('SET_LOADER', true)
    commit('RESET_EVENT_ERROR')
    return EventService.reviewVerifyBlockEvent(event, slug, token)
      .then((response) => {
        if (response.data.success) {
          const { event } = response.data
          dispatch('setEvent', { event })
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_EVENT_ERROR', error.response.data)
        } else {
          commit('SET_EVENT_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  inviteGuest ({ commit }, { user, slug, token }) {
    commit('SET_LOADER', true)
    commit('RESET_EVENT_ERROR')
    return EventService.inviteGuest(user, slug, token)
      .then((response) => {
        if (response.data.success) {
          commit('SET_INVITE', response.data.invite)
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_EVENT_ERROR', error.response.data)
        } else {
          commit('SET_EVENT_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  getEventWithInvites ({ commit, dispatch }, { slug, currentUser }) {
    const { token } = currentUser
    commit('SET_LOADER', true)
    commit('RESET_EVENT_ERROR')
    commit('RESET_INVITES')
    return EventService.getEventWithInvites(slug, token)
      .then(response => {
        const { event } = response.data
        dispatch('setEvent', { event })
        dispatch('setIsOwner', { currentUser, event })
        commit('SET_INVITES', response.data.event.invites)
        commit('SET_INVITES_EVENT', response.data.event)
        commit('SET_LOADER', false)
      })
      .catch(error => {
        if (error.response) {
          commit('SET_EVENT_ERROR', error.response.data)
        } else {
          commit('SET_EVENT_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  toggleCountryPickerModal ({ commit }, { status }) {
    commit('TOGGLE_COUNTRY_PICKER', status)
  },
}
export const getters = {
  getEventBySlug: state => slug => {
    return state.events.find(event => event.slug === slug)
  },
}
