import WebhookService from '@/services/WebhookService.js'

export const namespaced = true

export const state = {
  perPage: 30,
  webhooks: [],
  metadata: {},
  isLoading: false,
  error: {},
}

export const mutations = {
  SET_WEBHOOKS (state, webhooks) {
    state.webhooks = webhooks
  },
  SET_LOADER (state, status) {
    state.isLoading = status
  },
  SET_WEBHOOKS_METADATA (state, metadata) {
    state.metadata = metadata
  },
  SET_WEBHOOKS_ERROR (state, error) {
    state.error = error
  },
  RESET_WEBHOOKS_ERROR (state) {
    state.error = {}
  },
}

export const actions = {
  getWebhooks ({ commit }, { perPage = state.perPage, page, token }) {
    commit('SET_LOADER', true)
    return WebhookService.getWebhooks(perPage, page, token)
      .then(response => {
        if (response.data.success) {
          commit('SET_WEBHOOKS', response.data.webhooks)
          commit('SET_WEBHOOKS_METADATA', response.data.meta)
          commit('SET_LOADER', false)
        }
      })
      .catch(error => {
        if (error.response) {
          commit('SET_WEBHOOKS_ERROR', error.response.data)
        } else {
          commit('SET_WEBHOOKS_ERROR', error.message)
        }
        commit('SET_LOADER', false)
      })
  },
  resetWebhooksError ({ commit }) {
    commit('RESET_WEBHOOKS_ERROR')
  },
}

export const getters = {

}
