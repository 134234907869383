import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    children: [
      // Dashboard
      {
        name: 'Dashboard',
        path: '',
        component: () => import('@/views/dashboard/Dashboard'),
        meta: {
          requiresAuth: true,
          title: 'Mambo Leo App Admin - Dashboard',
        },
      },
      // Pages
      {
        name: 'User Profile',
        path: 'profile',
        component: () => import('@/views/dashboard/pages/UserProfile'),
        meta: {
          requiresAuth: true,
          title: 'Mambo Leo App Admin - Profile',
        },
      },
      {
        name: 'Events',
        path: 'events',
        component: () => import('@/views/dashboard/pages/Events'),
        meta: {
          requiresAuth: true,
          title: 'Mambo Leo App Admin - Events',
        },
      },
      {
        name: 'Users',
        path: 'users',
        component: () => import('@/views/dashboard/pages/Users'),
        meta: {
          requiresAuth: true,
          title: 'Mambo Leo App Admin  - Users',
        },
      },
      {
        name: 'Webhooks',
        path: 'webhooks',
        component: () => import('@/views/dashboard/pages/Webhooks'),
        meta: {
          requiresAuth: true,
          title: 'Mambo Leo App Admin  - Webhooks',
        },
      },
      {
        name: 'Messaging',
        path: 'messaging',
        component: () => import('@/views/dashboard/pages/Messaging'),
        meta: {
          requiresAuth: true,
          title: 'Mambo Leo App Admin - Messaging',
        },
      },
    ],
  }, {
    name: 'signin',
    path: '/signin',
    component: () => import('@/views/auth/SignIn'),
    meta: {
      requiresAuth: false,
      title: 'Mambo Leo App Admin - Sign In',
    },
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}` || 'Mambo Leo App Admin'
  store.dispatch('userData/getCurrentUser').then(() => {
    const { currentUser } = store.state.userData

    if (to.matched.some(route => route.meta.requiresAuth)) {
      if (currentUser && currentUser.role === 'administrator') {
        next()
      } else {
        next({ name: 'signin', query: { redirectTo: to.path, message: 'Contact Mambo Leo' } })
      }
    } else {
      next()
    }
  })
})

export default router
