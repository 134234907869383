import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/auth`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
})

export default {
  loginUser (user) {
    return apiClient.post('/login', { user })
  },
  logoutUser (token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch('logout')
  },
  signUpUser (user) {
    return apiClient.post('/signup', { user })
  },
  requestReset (user) {
    return apiClient.post('/forgot-password', { user })
  },
  resetPassword (user, token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch('/reset-password', { user })
  },
  verifyEmail (token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch('/verify')
  },
}
