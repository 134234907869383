import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
})

export default {
  sendPushNotification (token, notification) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/notifications/push', { notification })
  },
}
